import { layerPush } from '../analytics/functions/track'

enum EVENTS {
  SUBSCRIBE_TO_MAILING_LIST = 'subscribeToMailinglist',
  FOOTER_SIGNUP = 'footerSignup',
  IDENTIFIED_USER_TRAITS = 'identifiedUserTraits',
  POPUP_MODAL_SHOWN = 'popupModalShown',
  POPUP_MODAL_CLICKED = 'popupModalClicked',
}

export const trackSubscribeToMailinglist = (email: string) => {
  layerPush({
    event: EVENTS.SUBSCRIBE_TO_MAILING_LIST,
    acceptsEmailMarketing: true,
    email,
  })
}

export const trackFooterSignup = (email: string) => {
  layerPush({
    event: EVENTS.FOOTER_SIGNUP,
    email,
  })
}

export const trackUserTraits = (traits: Record<string, any>) => {
  layerPush({
    event: EVENTS.IDENTIFIED_USER_TRAITS,
    traits,
  })
}

export const trackPopupModalShown = (name: string) => {
  layerPush({
    event: EVENTS.IDENTIFIED_USER_TRAITS,
    genericPopupEventData: {
      name,
    },
  })
}

export const trackPopupModalClicked = (name: string, cta: string) => {
  layerPush({
    event: EVENTS.IDENTIFIED_USER_TRAITS,
    genericPopupEventData: {
      name,
      cta,
    },
  })
}
