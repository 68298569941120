import config from 'core/config'
import { CROWDIN_IN_CONTEXT_LANG } from 'modules/crowdin/constants'

export const SUPPORTED_LANGUAGES = [
  'ar',
  'fr',
  'en',
  'es',
  'de',
  'he',
  'id',
  'zh-TW',
  'pt',
  'th',
  'da',
  'ja',
  'ko',
]

// Add the pseudo-language code for Crowdin In-Context tool
if (config('CROWDIN_IN_CONTEXT_ENABLED')) {
  SUPPORTED_LANGUAGES.push(CROWDIN_IN_CONTEXT_LANG)
}

export const FALLBACK_LANGUAGE = 'en'
export const DEFAULT_NS = 'default'
export const I18N_PROJECT = 'web'
export const I18N_BRAND = 'lumin'
