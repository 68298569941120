import Head from 'next/head'
import Script from 'next/script'
import { useEffect } from 'react'

import { initOptimizelyUserEnvironment } from '..'
import config from '../../../core/config'

import { useDisableAnalytics } from 'modules/analytics/hooks/useDisableAnalytics'

const ExperimentScriptTag = ({ language }) => {
  const disableAnalytics = useDisableAnalytics()

  if (!disableAnalytics) {
    return (
      <>
        <Head key="experiment_script_tag_setup">
          <script
            dangerouslySetInnerHTML={{
              __html: `
         window.optimizelyFeatureFlags = window.optimizelyFeatureFlags || [];
         window.language = "${language}";
         `,
            }}
          />
        </Head>
        <GoogleOptimizeScriptTagComponent language={language} />
        <OptimizelyScriptTagComponent language={language} />
      </>
    )
  }
  return null
}

const OptimizelyScriptTagComponent = ({ language }) => {
  const OPTIMIZELY_ID = config('OPTIMIZELY_ID')

  useEffect(() => {
    initOptimizelyUserEnvironment()
  }, [])

  if (!OPTIMIZELY_ID) {
    return null
  }

  return (
    <Script
      type="text/javascript"
      src={`https://cdn.optimizely.com/js/${OPTIMIZELY_ID}.js`}
      strategy="afterInteractive"
    />
  )
}

const GoogleOptimizeScriptTagComponent = ({ language }) => {
  const GOOGLE_OPTIMIZE_ID = config('GOOGLE_OPTIMIZE_ID')

  if (!GOOGLE_OPTIMIZE_ID) {
    return null
  }
  return (
    <Script
      type="text/javascript"
      src={`https://www.googleoptimize.com/optimize.js?id=${config(
        'GOOGLE_OPTIMIZE_ID'
      )}`}
      strategy="afterInteractive"
    />
  )
}

export default ExperimentScriptTag
