import {
  CheckoutContext,
  updateCurrency,
  getUserCountryCode,
  isSupportedCurrency,
  getCookie,
} from '@pangaea-holdings/pangaea-checkout'
import { useRouter } from 'next/router'
import { useContext, useEffect } from 'react'

import { removeQueryParam } from '../../../utils/removeQueryParam'
import { CURRENCY_COOKIE } from '../../cart/constants'
import { CURRENCIES } from '../currencies'

export type DetectAndSetCurrency = (country: string) => Promise<void>

export function useDetectAndSetCurrency() {
  const { dispatch } = useContext(CheckoutContext)
  const router = useRouter()

  useEffect(() => {
    const defaultCurrencyData = { currency_code: 'USD' }
    const detectAndSet = async (country: string | null) => {
      const currencyData =
        (country && CURRENCIES[country]) || defaultCurrencyData

      const currencyParam = router.query.currency as string
      const savedCurrency = getCookie(CURRENCY_COOKIE)
      const detectedCurrency =
        currencyParam || savedCurrency || currencyData.currency_code

      const currency = isSupportedCurrency(detectedCurrency)
        ? detectedCurrency.toUpperCase()
        : 'USD'

      dispatch(updateCurrency(currency))

      if (currencyParam) {
        removeQueryParam('currency', router)
      }
    }

    getUserCountryCode().then(detectAndSet)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])
}
