import Head from 'next/head'
import React, { useLayoutEffect } from 'react'
import Script from 'next/script'

import config from '../../../core/config'
import { layerPush } from '../functions/track'
import useTrackLocation from '../hooks/useTrackLocation'
import { useDisableAnalytics } from '../hooks/useDisableAnalytics'

const AnalyticsScriptTag = () => {
  const GTM_ID = config('TAG_MANAGER_ID')
  const disableAnalytics = useDisableAnalytics()

  useLayoutEffect(() => {
    if (!GTM_ID) {
      return
    }

    layerPush({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
  }, [GTM_ID])

  useTrackLocation()

  if (!GTM_ID || disableAnalytics) {
    return null
  }

  return (
    <>
      <Head key="analytics_tagmanager_script_dataLayer">
        <script> window.dataLayer = window.dataLayer || [] </script>
      </Head>
      <Script
        key="analytics_tagmanager_script"
        src={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`}
        strategy="afterInteractive"
      />
    </>
  )
}

export default AnalyticsScriptTag
