import React from 'react'
import config from '../../../core/config'
import { useDisableAnalytics } from '../../analytics/hooks/useDisableAnalytics'

const HeadPreload = () => {
  const OPTIMIZELY_ID = config('OPTIMIZELY_ID')
  const GOOGLE_API_KEY = config('GOOGLE_API_KEY')
  const GTM_ID = config('TAG_MANAGER_ID')
  const STOREFRONT_URL = config('STOREFRONT_API_BASEURL')
  const BRAND_SERVICES_URL = config('API_BASEURL')

  const disableAnalytics = useDisableAnalytics()

  return (
    <>
      {!disableAnalytics && (
        <>
          <link
            rel="preload"
            href={`//cdn.optimizely.com/js/${OPTIMIZELY_ID}.js`}
            as="script"
          />
          <link
            rel="preload"
            href={`https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`}
            as="script"
          />
        </>
      )}
      <link
        rel="preload"
        href={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
        as="script"
      />
      <link
        rel="preload"
        href="//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js"
        as="script"
      />

      <link
        rel="preload"
        href="/fonts/karla.woff2"
        as="font"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        href="https://use.typekit.net/af/f15774/0000000000000000000132cf/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3"
        as="font"
        crossOrigin="anonymous"
      />

      {!disableAnalytics && (
        <>
          <link
            rel="preconnect"
            href="//logx.optimizely.com"
            crossOrigin="anonymous"
          />
          <link rel="dns-prefetch" href="//logx.optimizely.com" />
        </>
      )}

      <link rel="preconnect" href="//cdn.segment.com" crossOrigin="anonymous" />
      <link rel="dns-prefetch" href="//cdn.segment.com" />

      {!disableAnalytics && (
        <>
          <link
            rel="preconnect"
            href="//cdn.heapanalytics.com"
            crossOrigin="anonymous"
          />
          <link rel="dns-prefetch" href="//cdn.heapanalytics.com" />
        </>
      )}

      <link rel="preconnect" href={STOREFRONT_URL} crossOrigin="anonymous" />
      <link rel="dns-prefetch" href={STOREFRONT_URL} />

      <link
        rel="preconnect"
        href={BRAND_SERVICES_URL}
        crossOrigin="anonymous"
      />
      <link rel="dns-prefetch" href={BRAND_SERVICES_URL} />
    </>
  )
}

export default HeadPreload
